import { getConfig } from 'config/config';
import { CircularProgress } from '@material-ui/core';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';

type Props = {
  renderItem: (post: any, idx: number) => ReactNode;
  getFirstBatch: () => Promise<void>;
  getMainBatch: () => void;
  FBLoading: boolean;
  MBLoading: boolean;
  FBTotal: number;
  MBTotal: number;
  items: any;
  onScrollableEnd?: () => void;
};

const { theme } = getConfig();

const PaginatedView: FC<Props> = (props) => {
  const { renderItem, getFirstBatch, getMainBatch, FBLoading, MBLoading, FBTotal, MBTotal, items, onScrollableEnd } =
    props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [listEnded, setListEnded] = useState(false);

  const isFirstBatchLoaded = items?.length >= FBTotal && FBTotal !== null;
  const isMainBatchLoaded = items?.length >= FBTotal + MBTotal && FBTotal !== null && MBTotal !== null;

  const fetchFirstBatch = () => {
    if (isOnline && !FBLoading && !isFirstBatchLoaded && (items.length < FBTotal || FBTotal === null)) {
      getFirstBatch();
    }
  };

  const fetchMainBatch = () => {
    if (isOnline && isFirstBatchLoaded && !MBLoading && (items.length < MBTotal || MBTotal === null)) {
      getMainBatch();
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;

      if (scrollHeight - scrollTop <= clientHeight + 50) {
        if (!isFirstBatchLoaded) {
          fetchFirstBatch();
        } else if (!isMainBatchLoaded) {
          fetchMainBatch();
        } else if (!listEnded) {
          onScrollableEnd?.();
          setListEnded(true);
        }
      }
    }
  };

  const checkContentHeight = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollHeight, clientHeight } = container;

      if (scrollHeight <= clientHeight) {
        if (!isFirstBatchLoaded) {
          fetchFirstBatch();
        } else {
          fetchMainBatch();
        }
      }
    }
  };

  // Update connectivity status when network changes
  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [isFirstBatchLoaded, items, MBTotal, FBTotal, FBLoading, MBLoading, listEnded, isOnline]);

  useEffect(() => {
    if (items.length === 0 && !isFirstBatchLoaded) {
      // Trigger initial first batch load
      fetchFirstBatch();
    } else if (!FBLoading && !MBLoading) {
      // Check if more items are needed to fill the viewport
      checkContentHeight();
    }
  }, [isFirstBatchLoaded, items, FBLoading, MBLoading, isOnline]);

  return (
    <div ref={containerRef} className="overflow-y-auto h-[calc(95vh-55px)] no-scrollbar">
      {items.map((post, i) => post && renderItem(post, i))}
      {(FBLoading || MBLoading) && (
        <div style={{ textAlign: 'center' }} key="loading-indicator">
          <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
        </div>
      )}
      <div className="h-24" />
    </div>
  );
};

export default PaginatedView;
